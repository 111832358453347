import React, { FC } from 'react';
import { useStyles } from './UserInitiatives.styles';
import classNames from 'classnames';

interface Props {
  initiatives: string[];
}

export const UserInitiatives: FC<Props> = ({ initiatives }) => {
  const styles = useStyles();

  return (
    <>
      {initiatives.length ? (
        initiatives.map((initiative, i) => (
          <div className={styles.initiativeRow} key={i}>
            {initiative}
          </div>
        ))
      ) : (
        <div className={classNames(styles.noInitiatives, styles.initiativeRow)}>Not participating yet</div>
      )}
      <a
        className={styles.aeOpportunitiesLink}
        target="_blank"
        href="https://sites.google.com/agileengine.com/agileengine-ua/internal-programs-initiatives"
      >
        More about AE opportunities
      </a>
    </>
  );
};
